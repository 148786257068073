import React, { useState, useEffect } from "react";
import Axios from "axios";
// import { ROOT_URL, API_URL } from "config/config";
import { API_URL } from "config/config";
import Navbar from "components/Navbar";
import HeaderStats from "components/HeaderStats";
import Footer from "components/Footer.js";
import { useParams } from "react-router-dom";

export default function Ebook() {
  let { id_ebook } = useParams();
  const [dataList1, setdataList1] = useState([]);

  useEffect(() => {
    (async () => {
      const resList1 = await Axios.get(`${API_URL}/api/list/t_ebook`).then(
        (res) => res.data.t_ebook
      );
      setdataList1(resList1);
      console.log(dataList1);
    })();
  }, []);

  return (
    <>
      {/* menampilkan Navigasi & Header */}
      <Navbar transparent />
      <HeaderStats />

      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 ">
        <div className="bg-blueGray-200 py-5 ">
          <h1 className="text-center text-2xl text-blueGray-800 font-semibold mb-6">
            Data E-Book
          </h1>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  LINK
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Info
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Abstraksi
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  file
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {dataList1.sort((a, b) => parseInt(b.tahun.split('/')[0]) - parseInt(a.tahun.split('/')[0])).map((item, key) =>  */}
              {dataList1.map((item, key) => (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left">
                    <div className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      <th>{item.judul_ebook}</th>
                    </div>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.gambar_ebook && item.gambar_ebook.url ? (
                        <img
                          src={item.gambar_ebook.url}
                          alt="Ebook Cover"
                          className="object-cover w-40 h-30 mx-auto mb-1 rounded-lg" // Menyesuaikan ukuran gambar lebih besar
                          style={{ width: "150px", height: "200px" }} // Atur ukuran gambar sesuai kebutuhan
                        />
                      ) : (
                        <span>Gambar belum di pasang</span>
                      )}
                    </td>
                  </td>

                  <th
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 pl-0 text-left"
                    style={{
                      textAlign: "justify",
                      wordWrap: "break-word",
                      whiteSpace: "pre-line",
                      maxWidth: "300px",
                    }}
                  >
                    Nomor Katalog : {item.no_katalog} <br></br>
                    Nomor Publikasi : {item.no_publikasi} <br></br>
                    ISSN / ISBN : {item.issn_isbn} <br></br>
                    Tanggal Rilis : {item.tgl_rilis}
                  </th>

                  <th
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 pl-0 text-left"
                    style={{
                      textAlign: "justify",
                      wordWrap: "break-word",
                      whiteSpace: "pre-line",
                      maxWidth: "300px",
                    }}
                  >
                    {item.abstraksi}
                  </th>

                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.file_ebook && item.file_ebook.url ? (
                      <a
                        href={item.file_ebook.url}
                        className="bg-indigo-500 text-white p-2 rounded"
                      >
                        Lihat Ebook
                      </a>
                    ) : (
                      <span>Link tidak tersedia</span>
                    )}
                  </td>
                  {/* <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {item.gambar_ebook}
                  </th> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br></br>

      <Footer />
      {/* <FooterAdmin /> */}
    </>
  );
}
