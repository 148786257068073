import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

// import Admin from "layouts/Admin.js";

// import Auth from "layouts/Auth.js";

// screens without layouts

// import Promosi from "screens/Promosi.js";
// import Informasi from "screens/Informasi.js";
// import Peta1 from "screens/Peta1.js";
// import Peta from "screens/Peta.js";

// import Landing from "screens/Landing.js";
// import Landing2 from "screens/Landing2.js";
// import Landing3 from "screens/Landing3.js";
// import Profile from "screens/Profile.js";
// import Dashboard from "screens/Dashboard.js";
import Beranda from "screens/Beranda.js";
import ListFokus from "screens/ListFokus";
import ListAspek from "screens/ListAspek";
import ListBidangIndikator from "screens/ListBidangIndikator";
import ListUrusan from "screens/ListUrusan";
import ListObjek from "screens/ListObjek";
import ListData from "screens/ListData";
import ListDssd from "screens/ListDssd";
import SubUrusan from "screens/SubUrusan";

import ListRpd from "screens/ListRpd";

import ListAspekIku from "screens/ListAspekIku";
import ListAspekIkk from "screens/ListAspekIkk";
import ListIndikatorIku from "screens/ListIndikatorIku";
import ListFokusIkk from "screens/ListFokusIkk";
import ListBidangIkk from "screens/ListBidangIkk";
// import Data from "screens/Data";

import GetData from "screens/GetData";
import GetDssd from "screens/GetDssd";
import GetMetadata from "screens/GetMetadata";
import GetIndikator from "screens/GetIndikator";
import GetIndikatorIku from "screens/GetIndikatorIku";
import GetIndikatorIkk from "screens/GetIndikatorIkk";
// import Index from "screens/Index.js";
//import HalamanUtama from "screens/bda/HalamanUtama";

// import KinerjaOpd from "screens/grafik/KinerjaOpd";
// import Dashboard from "screens/admin/Dashboard";
import Pencarian from "screens/Pencarian";
import ListTujuanSasaran from "screens/TujuanSasaran";
import ListTujuan from "screens/ListTujuan";
import ListSasaran from "screens/ListSasaran";
import ListIndikator from "screens/ListIndikator";
import DokumentasiAPI from "screens/DokumentasiAPI";
import Ebook from "screens/Ebook";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/" exact component={Beranda} />
      <Route path="/Dashboard/Ebook" component={Ebook} />
      <Route path="/Dashboard/ListObjek" component={ListObjek} />
      <Route path="/Dashboard/ListUrusan" component={ListUrusan} />
      <Route path="/Dashboard/ListAspek" component={ListAspek} />
      <Route path="/Dashboard/ListTujuan" component={ListTujuan} />
      <Route path="/Dashboard/ListSasaran/:tujuan_id" component={ListSasaran} />
      <Route
        path="/Dashboard/ListIndikator/:sasaran_id"
        component={ListIndikator}
      />
      <Route path="/Dashboard/ListRpd" component={ListRpd} />
      <Route
        path="/Dashboard/ListTujuanSasaran"
        component={ListTujuanSasaran}
      />
      <Route path="/Dashboard/ListAspekIku" component={ListAspekIku} />
      <Route path="/Dashboard/ListAspekIkk" component={ListAspekIkk} />
      <Route
        path="/Dashboard/ListBidangIkk/:bidang_id"
        component={ListBidangIkk}
      />
      <Route
        path="/Dashboard/ListFokusIkk/:fokus_id"
        component={ListFokusIkk}
      />
      <Route
        path="/Dashboard/ListIndikatorIku/:aspek_id"
        exact
        component={ListIndikatorIku}
      />

      {/* <Route path="/Dashboard/Dashboard" component={Dashboard} /> */}
      <Route
        path="/Dashboard/ListBidangIndikator/:fokus_id"
        exact
        component={ListBidangIndikator}
      />
      <Route path="/Dashboard/ListData/:objek_id" exact component={ListData} />
      <Route path="/Dashboard/ListDssd/:urusan_id" exact component={ListDssd} />
      <Route
        path="/Dashboard/SubUrusan/:urusan_id"
        exact
        component={SubUrusan}
      />
      <Route
        path="/Dashboard/ListFokus/:aspek_id"
        exact
        component={ListFokus}
      />
      <Route
        path="/Dashboard/GetIndikator/:indikator_id"
        exact
        component={GetIndikator}
      />
      <Route
        path="/Dashboard/GetIndikatorIku/:aspek_id"
        exact
        component={GetIndikatorIku}
      />

      <Route
        path="/Dashboard/GetIndikatorIkk/:bidang_id"
        exact
        component={GetIndikatorIkk}
      />

      <Route path="/Dashboard/GetData/:data_id" exact component={GetData} />
      <Route path="/Dashboard/GetDssd/:data_id" exact component={GetDssd} />
      <Route
        path="/Dashboard/GetMetadata/:data_id"
        exact
        component={GetMetadata}
      />
      <Route path="/pencarian/:keys" component={Pencarian} />

      <Route path="/Dashboard/DokumentasiAPI" component={DokumentasiAPI} />

      {/* <Route path="/auth" component={Auth} /> */}

      {/* <Route path="/dashboard/data/:objek_id" exact component={Data} />       */}

      {/* <Route path="/dashboard/meta-data/:data_id" exact component={MetaData} /> */}

      {/* <Route path="/promosi/:id" component={Promosi} /> */}
      {/* <Route path="/informasi/:id" component={Informasi} /> */}
      {/* <Route path="/dashboard" component={Admin} /> */}
      {/* <Route path="/dashboard" component={Dashboard} />    */}

      {/* <Route path="/peta1/:id" component={Peta1} /> */}
      {/* <Route path="/peta" component={Peta} /> */}

      {/* add routes without layouts */}
      {/* <Route path="/landing" exact component={Landing} />
      <Route path="/landing2" exact component={Landing2} />
      <Route path="/landing3" exact component={Landing3} /> */}
      {/* <Route path="/profile" exact component={Profile} /> */}
      {/* <Route path="/grafik/kinerja-opd" component={KinerjaOpd} /> */}

      {/* add redirect for first page */}

      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
