//export const API_URL = "http://localhost";

// export const API_URL =
//   "http://localhost/satudata.banjarnegarakab.go.id/satudata_backoffice";

// export const ROOT_URL =
//   "http://localhost/satudata.banjarnegarakab.go.id/satudata_backoffice";

// export const API_URL = "https://satudata.banjarnegarakab.go.id/satudata_api";

export const API_URL =
  "https://satudata.banjarnegarakab.go.id/satudata_backoffice";
export const ROOT_URL =
  "https://satudata.banjarnegarakab.go.id/satudata_backoffice";
